import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAdminData } from '../shared/interfaces/users.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private profileSubject: BehaviorSubject<IAdminData | null>;
  public currentProfile: Observable<IAdminData | null>;
  constructor(private http: HttpClient) {
    this.profileSubject = new BehaviorSubject<IAdminData | null>(
      JSON.parse(localStorage.getItem('profile')!)
    );
    this.currentProfile = this.profileSubject.asObservable();
  }

  updateProfileData(data: IAdminData) {
    this.profileSubject.next(data);
  }

  getProfile() {
    return this.http
      .get<any>(`${this.apiUrl}user/profile`)
      .pipe(catchError(this.handleError));
  }

  changePassword(body: { password: string; oldPassword: string }) {
    return this.http
      .patch<any>(`${this.apiUrl}auth/changePassword`, body)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('service::error ', error.error);
    }
    let errorMessage = '';
    if (Array.isArray(error.error.statusMessage)) {
      errorMessage = error.error.statusMessage.toString();
    } else {
      errorMessage = error.error.statusMessage;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => error.error);
  }
}
