import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PermissionsService {
  private isVerifySubject!: BehaviorSubject<boolean>;

  constructor(private router: Router) {
    this.isVerifySubject = new BehaviorSubject<boolean>(false);
  }

  get getVerified() {
    return this.isVerifySubject.value;
  }

  verifySuccess(isVerify: boolean) {
    this.isVerifySubject.next(isVerify);
  }

  canActivate(): boolean {
    if (this.isVerifySubject.value) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}

export const verfiedGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivate();
};
